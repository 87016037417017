import React, { Component } from "react";
import {
  MdExpandLess,
  MdExpandMore,
  MdOutlineArrowRightAlt,
} from "react-icons/md";
import InstructionStep from "../InstructionStep/InstructionStep";
import MoonLightLinks from "../MoonlightLinks/MoonlightLinks";

import "./InstructionsContainer.scss";

class InstructionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areInstructionsExpanded: false,
      steamCredentialsExpanded: false,
    };
  }
  render() {
    return (
      <section className="instructions-container">
        <div
          className="instructions-header"
          onClick={() => {
            this.setState((prevState) => ({
              areInstructionsExpanded: !prevState.areInstructionsExpanded,
            }));
          }}
        >
          <div className="text-2xl pb-2">How to Start Playing</div>
          <span>
            {this.state.areInstructionsExpanded ? (
              <MdExpandLess size="1.5rem" />
            ) : (
              <MdExpandMore size="1.5rem" />
            )}
          </span>
        </div>
        {this.state.areInstructionsExpanded && (
          <div>
            {
              <div>
                <iframe
                  className="w-fit"
                  height="100%"
                  src="https://www.youtube-nocookie.com/embed/x1mRGYrcUNE"
                  title="Get started with cloud gaming on Gameaway"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <p>
                  <strong>OR</strong>
                </p>
              </div>
            }
            <InstructionStep header="Step 1">
              Select a game that <strong>you own</strong> or a free-to-play game
              from the list of games in the dropdown. You will have to sign in
              to Steam or Epic later to validate ownership.
            </InstructionStep>
            <InstructionStep header="Step 2">
              Click on the “Start Game” button - after a brief wait, the State
              will change to “Running” and you’ll see an IP address to connect
              to your game.
            </InstructionStep>
            <InstructionStep header="Step 3">
              <MoonLightLinks />
            </InstructionStep>
            <InstructionStep header="Step 4">
              Open Moonlight and click the + button on the top right. You have
              to do this everytime you start a game.
            </InstructionStep>
            <InstructionStep header="Step 5">
              Copy the IP address from step 2 and enter it into the field on
              Moonlight. On iOS, prefix the IP with ::ffff: and surround with
              []. For instance, if your IP is 1.2.3.4, enter [::ffff:1.2.3.4]
            </InstructionStep>
            <InstructionStep header="Step 6">
              The first time you play, Moonlight will give you a PIN, enter it
              into the PIN field above and click Set PIN.
            </InstructionStep>
            <InstructionStep header="Step 7">
              In Moonlight settings, set your video codec to HEVC (if supported
              by your device).
              <div className="moonlight-screenshots">
                <img
                  alt="moonlight-codec-screenshot"
                  src={`./moonlight-hevc.png`}
                />
              </div>
              You can also adjust the resolution and FPS to your liking.
            </InstructionStep>
            <InstructionStep header="Step 8">
              On Moonlight once the lock symbol disappears from the field, click
              on it, and run <strong>Desktop</strong>
              <div className="moonlight-screenshots">
                <img src="./moonlight-before.png" />
                <MdOutlineArrowRightAlt size="3rem" />
                <img src="./moonlight-after.png" />
              </div>
            </InstructionStep>

            <InstructionStep header="Step 9">
              Log in to Steam and your game will start automatically. If you
              need to bring up the keyboard on a mobile device or tablet, tap
              the screen with 3 fingers. Enjoy your game!
              <br />
              <strong>
                IMPORTANT: DO NOT CLOSE ANY RUNNING COMMAND PROMPT SCRIPTS ON
                THE REMOTE PC
              </strong>
            </InstructionStep>
            <InstructionStep header="Step 10">
              To stop your instance, click the "Stop Game" button, shutdown the
              remote PC, or do nothing, and it will automatically shutdown in 15
              minutes.
            </InstructionStep>
            <InstructionStep header="Step 11">
              <strong>Important:</strong> Before shutting down the remote PC,
              quit your game and check that cloud saves are uploaded to Steam.
              It will look like the screenshot below.
              <div className="moonlight-screenshots">
                <img src="./steam-cloud.png" />
              </div>
            </InstructionStep>
            <h4>
              You can also use console controllers like an XBOX controller 🎮.
              Have fun!
            </h4>
          </div>
        )}
      </section>
    );
  }
}

export default InstructionsContainer;
