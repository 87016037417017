import { useState, useEffect } from "react";
import React from "react";
import clsx from "classnames";
import AuthContext from "../../components/AuthContext";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import Footer from "../../components/Footer/Footer";
export default function UserPage() {
  // pass useState() the initial value

  // machineType can be "ondemand", "100gb", "200gb", "1tb"
  const [dataLoaded, setDataLoaded] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [payments, setPayments] = useState([]);
  const [manageUrl, setManageUrl] = useState(null);

  const { user, signOut, signIn } = React.useContext(AuthContext);

  useEffect(() => {
    if (!user) {
      console.log("User signInSession is null, waiting...");
      return;
    }
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://h9e11lic8c.execute-api.ap-south-1.amazonaws.com/prod/",
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: user.signInUserSession.idToken.jwtToken,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // Assuming the API returns an object with subscriptions and payments arrays
        setSubscriptions(data.subscriptions);
        setPayments(data.payments);
        setManageUrl(data.manage_url);
        setDataLoaded(true); // Update state to indicate data is loaded
      } catch (error) {
        console.error("There was a problem with your fetch operation:", error);
        // Handle errors here, such as setting an error state
      }
    };

    // Call the function
    fetchData();
  }, [user]); // The empty array causes this effect to only run once on mount

  return (
    <div className="pt-0 max-w-[1245px] px-[24px] my-0 mx-auto min-h-screen grid">
      <SignedOutHeader />
      <div className="relative flex flex-col mx-auto w-full justify-center items-left gap-6 overflow-x-auto">
        <div className="text-lg font-bold">Subscriptions</div>
        <div
          className={clsx("skeleton w-full h-32", {
            hidden: dataLoaded,
          })}
        ></div>
        <div
          className={clsx("overflow-x-auto w-full", { hidden: !dataLoaded })}
        >
          <table className="table table-zebra table-xs md:table-md">
            <thead>
              <tr className="w-full">
                <th className="truncate">Name</th>
                <th className="truncate">Quantity</th>
                <th className="truncate">Amount</th>
                <th className="truncate">Next Payment</th>
                <th className="truncate">Manage</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions
                .filter((sub) => sub.status === "active")
                .map((sub) => (
                  <tr key={sub.id} className="w-full">
                    <td>
                      {
                        sub.payment_settings.payment_method_options.card
                          .mandate_options.description
                      }
                    </td>
                    {/* Product Name */}
                    <td>{sub.items.data[0].quantity}</td> {/* Quantity */}
                    <td>
                      ₹
                      {(
                        sub.payment_settings.payment_method_options.card
                          .mandate_options.amount / 100
                      ).toFixed(2)}
                    </td>
                    {/* Amount */}
                    <td>
                      {new Date(
                        sub.current_period_end * 1000
                      ).toLocaleDateString()}
                    </td>
                    {/* Next Payment Date */}
                    <td>
                      <a className="underline" target="_blank" href={manageUrl}>
                        Manage Subscription
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="text-lg font-bold">Payments</div>
        <div
          className={clsx("skeleton w-full h-32", {
            hidden: dataLoaded,
          })}
        ></div>
        <div
          className={clsx("overflow-x-auto w-full", { hidden: !dataLoaded })}
        >
          <table className="table table-zebra table-xs md:table-md">
            {/* head */}
            <thead>
              <tr>
                <th className="truncate">Product Name</th>
                <th className="truncate">Quantity</th>
                <th className="truncate">Amount</th>
                <th className="truncate">Date</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr key={payment.id}>
                  <td>{payment.lines.data[0].description}</td>
                  <td>{payment.lines.data[0].quantity || "N/A"}</td>
                  <td>₹{(payment.amount_paid / 100).toFixed(2)}</td>
                  <td>
                    {new Date(payment.created * 1000).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
}
