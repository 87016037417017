import React from "react";
import "./MoonlightLinks.scss";

function MoonLightLinks({ userEmail, checkoutLink, buttonText }) {
  return (
    <div className="moonlight-download-links">
      Download Moonlight <br />
      Pick your client:
      <a
        className="underline"
        target="_blank"
        href="https://github.com/moonlight-stream/moonlight-qt/releases/download/v5.0.1/MoonlightSetup-5.0.1.exe"
      >
        Windows
      </a>
      <a
        className="underline"
        target="_blank"
        href="https://github.com/moonlight-stream/moonlight-qt/releases/download/v5.0.1/Moonlight-5.0.1.dmg"
      >
        MacOS
      </a>
      <a
        className="underline"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.limelight"
      >
        Android and Smart TVs
      </a>
      <a
        className="underline"
        target="_blank"
        href="https://apps.apple.com/us/app/moonlight-game-streaming/id1000551566"
      >
        iOS
      </a>
      <br />
      Other platforms under Client Downloads
      <a
        className="underline"
        target="_blank"
        href="https://moonlight-stream.org/#"
      >
        here.
      </a>
    </div>
  );
}

export default MoonLightLinks;
