import React from "react";
import AuthContext from "../AuthContext";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import clsx from "classnames";
import { usePostHog } from "posthog-js/react";

export default function SignedOutHeader(props) {
  const { userEmail, signIn, signOut } = React.useContext(AuthContext);
  const navigate = useNavigate(); // Initialize navigate function
  const posthog = usePostHog();

  const handleEventAndNavigate = (path, eventName) => {
    posthog?.capture(eventName, { path, userEmail });
  };

  return (
    <div className="navbar bg-base-100 pt-2 pb-8">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <a
                href="/"
                onClick={() => handleEventAndNavigate("/", "home_from_header")}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/gamelist"
                onClick={() =>
                  handleEventAndNavigate("/gamelist", "gamelist_from_header")
                }
              >
                Game List
              </a>
            </li>
            <li>
              <a
                href="/pricing"
                onClick={() =>
                  handleEventAndNavigate("/pricing", "pricing_from_header")
                }
              >
                Pricing
              </a>
            </li>
            <li>
              <a
                href="/faq"
                onClick={() =>
                  handleEventAndNavigate("/faq", "faq_from_header")
                }
              >
                FAQs
              </a>
            </li>
            <li className={clsx("block", { hidden: !userEmail })}>
              <a
                href="/user"
                onClick={() =>
                  handleEventAndNavigate("/user", "profile_from_header")
                }
              >
                Profile
              </a>
            </li>
          </ul>
        </div>
        <a className="btn btn-ghost text-xl hidden lg:inline-flex" href="/">
          <img src="./favicon-32x32.png" />
          <span>GameAway</span>
        </a>
      </div>
      <a className="btn btn-ghost text-xl lg:hidden navbar-center" href="/">
        <span>GameAway</span>
      </a>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
          <li>
            <a
              href="/gamelist"
              onClick={() =>
                handleEventAndNavigate("/gamelist", "gamelist_from_header")
              }
            >
              Game List
            </a>
          </li>
          <li>
            <a
              href="/pricing"
              onClick={() =>
                handleEventAndNavigate("/pricing", "pricing_from_header")
              }
            >
              Pricing
            </a>
          </li>
          <li>
            <a
              href="/faq"
              onClick={() => handleEventAndNavigate("/faq", "faq_from_header")}
            >
              FAQs
            </a>
          </li>
        </ul>
      </div>
      <div className="navbar-end gap-2">
        {userEmail ? (
          <a className="btn btn-primary" onClick={signOut}>
            Sign Out
          </a>
        ) : (
          <a className="btn btn-primary" onClick={signIn}>
            Sign In
          </a>
        )}
        <a
          className={clsx(
            "btn btn-ghost btn-circle avatar",
            userEmail ? "hidden lg:inline-flex" : "hidden"
          )}
          href="/user"
          onClick={() => handleEventAndNavigate("/user", "profile_from_header")}
        >
          <UserCircleIcon tabIndex={0} role="button"></UserCircleIcon>
        </a>
      </div>
    </div>
  );
}
