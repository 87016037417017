import React, { Component } from "react";
import "./GameSelection.scss";
import clsx from "classnames";
import { gameList } from "../../gameList";

class GameSelection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      vm,
      isYourOwnGamePlan,
      allowNewGame,
      isDisabled,
      gameSize,
      gameName,
      region,
      newGameName,
      handleNewGameChange,
      handleGameChange,
      handleRegionChange,
      handleGameSizeChange,
      handleNewGameStoreChange,
      newGameStore,
      isHourlyPlan,
      accessAllGames,
    } = this.props;
    const gameValue = function getGameValue(game) {
      return game.store === "Epic" ? `${game.name} ${game.store}` : game.name;
    };

    return (
      <div className="game-selection">
        <label
          htmlFor="game-selection-dropdown"
          className="game-selection-label"
        >
          Select Game
        </label>
        {allowNewGame ? (
          <div className="create-new-game">
            <label>
              New game name:
              <input
                type="text"
                name="game_name"
                value={newGameName}
                onChange={handleNewGameChange}
              />
            </label>
            <label>
              Game size (in GB):
              <input
                type="number"
                name="game_size"
                value={gameSize}
                onChange={handleGameSizeChange}
              />
            </label>

            <label htmlFor="store-select">Choose Epic or Steam:</label>
            <select
              value={newGameStore}
              onChange={handleNewGameStoreChange}
              id="store-select"
            >
              <option value="steam">Steam</option>
              <option value="epic">Epic</option>
            </select>
          </div>
        ) : (
          <div className="flex flex-row gap-2">
            <select
              id="game-dropdown"
              value={gameName}
              disabled={isDisabled}
              onChange={handleGameChange}
              className={clsx(
                "px-4 h-12 rounded-md select select-bordered w-full max-w-xs w-5/6"
              )}
              placeholder="Choose a game..."
            >
              <option value="VM" disabled={!vm}>
                Virtual Gaming PC (Plan only)
              </option>
              {gameList
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((game) => {
                  return (
                    <option
                      key={game.name}
                      value={gameValue(game)}
                      disabled={
                        game.hourlyPlanOnly && !isHourlyPlan && !accessAllGames
                      }
                    >
                      {game.store === "Epic"
                        ? `${game.name} (${game.store})`
                        : game.name}
                    </option>
                  );
                })}
            </select>
            <select
              id="region-dropdown"
              value={region}
              onChange={handleRegionChange}
              disabled={gameName !== "VM"}
              className={clsx(
                "px-4 w-fit select select-bordered max-w-xs text-2xl appearance-none w-fit"
              )}
              placeholder="🇮🇳"
            >
              <option value="ap-south-1">🇮🇳</option>
              <option value="me-south-1">🇦🇪</option>
              <option value="ap-east-1">🇹🇭🇻🇳🇵🇭</option>
              <option value="eu-south-1">🇹🇷🇪🇺</option>
            </select>
          </div>
        )}
      </div>
    );
  }
}

export default GameSelection;
