import React from "react";
import AuthContext from "../AuthContext";
import { useState } from "react";
import ReferredBanner from "../ReferredBanner/ReferredBanner";
import { usePostHog } from "posthog-js/react";

export default function ReferralBanner(props) {
  const { user } = React.useContext(AuthContext);
  const [copied, setCopied] = useState(false);
  const posthog = usePostHog();

  const handleCopyClick = () => {
    const referralLink = `https://www.gameaway.in?referrerUsername=${user.username}`;
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
    posthog?.capture("referral_link_copied", {
      userEmail: user.email,
      referrerUsername: user.username,
    });
  };
  const hasReferrerUsername =
    user?.signInUserSession?.idToken?.payload?.["custom:referrerUsername"];
  const params = new URLSearchParams(window.location.search);
  if (hasReferrerUsername && sessionStorage.getItem("referrerUsername")) {
    return <ReferredBanner show={true} signedOut={false} />;
  }
  return (
    <div className="card bg-accent text-accent-content w-fit lg:w-11/12 flex flex-col lg:flex-row mb-8 mx-auto gap-6">
      <div className="card-body flex flex-col lg:flex-row">
        <div className="flex flex-col w-full lg:w-2/3 justify-items-center items-center text-center mx-auto">
          <h2 className="card-title">Give playtime, get playtime! 🎉</h2>
          <p>
            Refer a friend using the link. If they purchase a Virtual Gaming PC
            plan, you{" "}
            <strong>both get 5 free hours of playtime (₹262.5 value).</strong>
          </p>
        </div>
        <div className="lg:divider-horizontal"></div>
        <div className="card-actions mx-auto w-full lg:w-1/3 items-center">
          <button className="btn btn-primary w-full" onClick={handleCopyClick}>
            {copied ? (
              "Link Copied!"
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
                  />
                </svg>
                Copy Referral Link
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
