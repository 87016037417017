// HowItWorks.js

import React from "react";
import {
  CpuChipIcon,
  GlobeAsiaAustraliaIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/solid";

class HowItWorks extends React.Component {
  render() {
    const { howItWorksRef } = this.props;
    return (
      <section ref={howItWorksRef} className="pt-16">
        <h2 className="text-4xl font-extrabold no-underline pb-10">
          How it Works
        </h2>
        <div className="flex flex-col md:flex-row text-center text-2xl">
          <div className="md:w-1/3 flex-col gap-10 py-[16px] px-[24px]">
            <div className="text-center inline-block">
              <GlobeAsiaAustraliaIcon className="h-28 w-28 text-primary" />
            </div>
            <div className="text-center">
              Use our web dashboard to start your dedicated Virtual Gaming PC or
              pick a game that <strong>you must already own</strong> on Steam or
              Epic.
            </div>
          </div>
          <div className="md:w-1/3 flex-col gap-10 py-[16px] px-[24px]">
            <div className="text-center inline-block">
              <CpuChipIcon className="h-28 w-28 text-primary" />
            </div>
            <div className="text-center">
              Start our gaming PC that runs on powerful servers near you.
            </div>
          </div>
          <div className="md:w-1/3 flex-col gap-10 py-[16px] px-[24px]">
            <div className="text-center inline-block">
              <PlayCircleIcon className="h-28 w-28 text-primary" />
            </div>
            <div className="text-center">
              Play your games through{" "}
              <a target="_blank" href="https://moonlight-stream.org/">
                Moonlight
              </a>{" "}
              and enjoy low latency and great image quality on your laptop,
              tablet or phone!
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HowItWorks;
